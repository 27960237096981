/* --root{} */
* {
  font-family: "Mabry Pro", "sans-serif";
}
@media (min-width: 450px) {
  .fixed-container {
    box-shadow: #d7c0ae 0 1px 2px 0;
  }
}
body,
html,
#root,
#root > div,
.fixed-container {
  min-height: 100vh;
}
body {
  background: #ede5d4;
  color: #1a202c;
}
button {
  color: #1a202c;
}
a,
.a-span {
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.row {
  --bs-gutter-x: 0rem;
}
.h-100 {
  min-height: calc(100vh - 92px);
  height: auto !important;
}
.fixed-container {
  width: 100%;
  max-width: 450px;
  padding: 0;
  position: relative;
}
.page-footer {
  background: #f5eee0;
  padding: 1rem;
  margin-bottom: -1rem;
  font-size: 10px;
  width: 100%;
  max-width: 450px;
  position: relative;
}
.page-footer .a-span {
  font-weight: bold;
  font-size: 10px;
}
.page-footer .footer-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.page-footer .footer-links li {
  margin-right: 60px;
  margin-bottom: 10px;
}
.page-footer .about-heading {
  margin-top: 30px;
  color: #b2aba3;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
}
.page-footer-copyright {
  border-top: solid 1px #85807b;
  text-align: center;
  color: #85807b;
  padding: 0.5rem 0;
  background: #f5eee0;
  font-size: 12px;
}
.page-header {
  margin-bottom: 0 !important;
  background: #f5eee0;
  padding: 0.5rem;
  border-bottom: solid 1px black;
  height: 72px;
  position: fixed;
  width: 100%;
  max-width: 450px;
  z-index: 1;
  flex-direction: column;
}
.page-header .upcoming-events {
  border: solid 1px black;
  border-radius: 10px;
  padding: 12px 16px;
  box-shadow: 0 3px 0 black;
  background: #f5ebe0;
  margin: 12px 1rem;
  display: none !important;
}
.upcoming-events-counter {
  background: #e8d9c9;
  border-radius: 50%;
  border: 1px #98591a solid;
  width: 24px;
  height: 24px;
  font-weight: bold;
  text-align: center;
}
.upcoming-events-icon {
  border: solid 1px black;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-left: 10px;
  padding: 5px 7px;
  display: none;
}
.upcoming-events-icon.open {
  display: block;
}
.upcoming-events-icon.close {
  display: none;
}
.fixed-container.has-upcoming-events .page-header {
  height: 145px;
}
.fixed-container.has-upcoming-events .page-header .upcoming-events {
  display: flex !important;
}
.page-header .logo {
  padding: 0.8rem 0 0 0.5rem !important;
}
.filter-header {
  padding: 0 1rem;
  border-bottom: solid 1px black;
  margin-bottom: 15px !important;
  position: fixed;
  width: 100%;
  max-width: 450px;
  top: 72px;
  margin-bottom: -72px;
  z-index: 1;
  background: #ede5d4;
}
.main-container-body .filter-header {
  position: fixed;
  top: 72px;
  z-index: 1;
}
.fixed-container.has-upcoming-events .filter-header {
  top: 145px;
}
.main-container.menu-open .main-container-body .filter-header {
  position: relative;
  top: auto;
  z-index: 1;
}
.filter-header .archive-date-switcher-container {
  font-size: 14px;
  font-weight: 500;
  padding: 7px 0;
}

.filter-header .archive-date-switcher-container .archive-date-button {
  border-radius: 6px;
  border: 1.4px solid rgb(0, 0, 0);
  box-shadow: rgb(0, 0, 0) 0.7px 0.7px 0 0;
  background: #ecbe91;
  padding: 5px;
  cursor: pointer;
}

.filter-header .archive-date-switcher-container .archive-date-button.left {
  margin-right: 10px;
}
.filter-header .archive-date-switcher-container .archive-date-button.right {
  margin-left: 10px;
}

.filter-header .archive-date-switcher-container .archive-date-button.disabled {
  background: #dbdbdb;
  color: #8f8f8f;
}

.filter-header .filter-options {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 10px 0;
}
.filter-header .filter-options li {
  display: flex;
  font-size: 12px;
  border: solid 1px black;
  border-radius: 15px;
  padding: 2px 10px;
  cursor: pointer;
  margin-right: 5px;
}
.filter-header .filter-options li.selected {
  background: rgb(233, 217, 186);
}
h3.filter-heading {
  font-weight: 700;
  margin: 15px 0;
  font-size: 15px;
  padding: 0 1rem !important;
  margin-top: 0px;
}
h3.filter-heading span {
  text-transform: capitalize;
}
.filter-header h3.filter-heading {
  padding: 0.5rem 0 !important;
  margin-top: 2px;
  margin-bottom: 0;
}
.filter-header .dropdown-filter {
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
}
.link-button {
  border: 2px solid;
  background: rgb(215, 192, 174);
  box-shadow: #000000 2px 2px 0px 0px;
  border-radius: 10px;
  font-size: 15px;
  padding: 0.5rem;
}
.event-card-list {
  padding: 0 1rem;
}
.header-margin {
  margin-top: 55px !important;
}
.filter-pills {
  list-style-type: "none";
  padding: 0 1rem;
  margin: 0 10px 20px 0;
  width: 100%;
  font-size: 14px;
}
.main-container.menu-open .main-container-body .header-margin {
  margin-top: 0 !important;
}

.filter-pills button {
  background: none;
  display: inline-block;
  border: solid 1px black;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
}
.filter-pills button.selected {
  background: white;
}

.event-card {
  padding: 5px;
  padding-bottom: 0px;
  border: 1px solid;
  border-radius: 10px;
  border: 1px solid #000;
  box-shadow: 0 3px 0 0 #000;
  /*box-shadow: 0 3px 0 0 #000, 1px 2px 10px 4px rgba(0, 0, 0, 0.30);*/
  background: #f5ebe0;
  cursor: pointer;
  margin-bottom: 1.25rem;
}

.event-card .primary-btn {
  margin-top: 0 !important;
  margin-bottom: 1.25rem;
}

.event-card .card-img-top {
  height: 216px;
  border: solid 1px black;
  background-size: cover;
  background-position: 50%;
  border-radius: calc(0.375rem - 1px);
  margin-bottom: 12px;
}
.event-card .card-img-top.expired {
  filter: grayscale(1);
}
.event-card .card-img-top .share-btn {
  background-color: #ecbe91;
  border: 1px solid rgb(0, 0, 0);
  padding: 11px;
  border-radius: 50%;
  position: relative;
  top: 10px;
  left: calc(100% - 53px);
  font-size: 12px;
  font-weight: 600;
  height: fit-content;
}
.image-counter {
  position: relative;
  top: 102%;
  padding: 3.76px;
  height: 4px;
}
/*.event-card .card-img-top .share-btn svg { margin-left: 5px; }*/
.event-card .card-body {
  margin-top: 10px;
  padding: 0 10px;
}
.event-card .card-body:after {
  clear: both;
  content: "";
  display: block;
}
.event-card .card-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.event-card .card-icon {
  font-size: 16px;
  font-weight: 400;
  color: #161616;
  margin-bottom: 1rem;
  height: 25px;
  display: flex;
  align-items: center;
}
.event-card .card-icon:last-of-type {
  margin-bottom: 1rem;
}
.event-card .card-icon a {
  display: flex;
}
.event-card .card-icon i {
  background-color: #ecbe91;
  padding: 5px;
  border-radius: 50%;
  border: solid 1px #000000;
  margin-right: 5px;
  font-size: small;
  float: left;
  display: flex;
}
.event-card .card-icon-text {
  display: flex;
  align-items: center;
}
.event-card .card-icon-text.card-icon-link {
  text-decoration: underline;
}
.card-text {
  hyphens: auto;
  hyphenate-limit-chars: 5 3 3;
  font-size: 16px;
  color: #434343;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.modal-dialog {
  margin: 130px calc((100% - 350px) / 2) 0;
  max-width: 350px;
  z-index: 1051;
  border-radius: 16px;
  border: 2px solid #000;
  background: #fff;
  box-shadow: 1.935px 1.935px 0px 0px #000, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.modal-dialog .alert {
  padding: 7px 0px;
  text-align: center;
  margin: 0 19px;
  position: absolute;
  z-index: 1053;
  width: calc(100% - 38px);
  top: calc((100% - 40px) / 2);
  background: #161616;
  opacity: 80%;
  border-radius: 8px;
  color: white;
  display: none;
}
.modal-header {
  padding: 0px;
  border-bottom: 0px;
  align-items: flex-start;
}
.modal-header .close-btn {
  width: auto;
  height: auto;
  color: black;
  padding: 16px 16px 0;
  cursor: pointer;
}
.modal-header .logo-container {
  width: calc(100% - 32px);
  text-align: center;
  padding: 24px 16px 12px 16px !important;
}
.modal-header .btn-close {
  cursor: pointer;
}
.modal-body {
  padding: 0 16px;
}
.modal-body p {
  margin-bottom: 5px;
}
.modal-body .input-group {
  height: 50px;
  margin-bottom: 15px;
  background: #f7f1e5;
  border: solid 2px black;
  border-radius: 8px;
}
.modal-body .input-group input {
  background: none;
  border: 0px;
}
.modal-body .input-group .input-group-text {
  background: none;
  border: 0px;
  cursor: pointer;
}
.modal-body .primary-btn {
  height: 50px;
  width: 100%;
  margin-bottom: 25px;
}
.modal-body .primary-btn svg {
  margin-left: 5px;
}
.no-events-header {
  font-weight: 700;
  line-height: 1.33;
  font-size: 1.5rem;
  text-align: center;
  color: rgb(138, 108, 45);
}
#loginModal #loginModalLoginReasonText {
  margin-bottom: 16px;
}
#loginModal .sso-btn {
  box-shadow: none;
  border: solid 1px #e2e8f0;
  width: 100%;
  background: white;
  padding: 10px;
  border-radius: 40px;
}
#loginModal .sso-btn img {
  margin-right: 10px;
}

.share-container,
.event-details-container,
.event-checkout-container,
.upcoming-experiences,
.upcoming-event-details {
  padding: 0 1rem;
  margin-top: 20px;
}
.event-details-container {
  padding-bottom: 90px !important;
}
.event-checkout-container {
  min-height: calc(100vh - 365px);
}

.static-content {
  padding: 0 1rem;
  margin-top: 20px;
  font-size: 10px;
}
.static-content h1,
.static-content h2 {
  font-size: 12px;
}

.static-content a {
  text-decoration: underline;
  font-size: 10px;
}

.share-container .primary-btn {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  border: 2px solid #161616;
  background: #ecbe91;
  box-shadow: 2px 2px 0px 0px #000;
  margin-top: 20px;
  padding: 10px;
  &:active {
    background: #ecbe91 !important;
    box-shadow: none;
    border: 2px solid;
    margin-top: 22px;
    margin-left: 2px;
  }
  &:hover {
    background: #ecbe91;
    border: 2px solid;
  }
}
.share-container .join-venn-link {
  display: block;
  text-align: center;
  text-decoration: underline;
  margin-top: 15px;
}

.share-title {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.primary-btn {
  cursor: pointer;
  text-decoration: none;
  outline: transparent solid 2px;
  outline-offset: 2px;
  color: inherit;
  border: 2px solid;
  background: #ecbe91;
  box-shadow: rgb(0, 0, 0) 2px 2px 0 0;
  border-radius: 10px;
  font-size: 15px;
  &:active {
    background: #ecbe91 !important;
    box-shadow: none;
    border: 2px solid;
    margin-top: 2px;
    margin-left: 2px;
  }
  &:hover {
    background: #ecbe91;
    border: 2px solid;
  }
  /*padding-inline-start: var(--chakra-space-2);*/
  /*padding-inline-end: var(--chakra-space-2);*/
  /*padding-top: var(--chakra-space-2);*/
  /*padding-bottom: var(--chakra-space-2);*/
}

.no-events-container {
  text-align: center;
  margin-bottom: 50px;
}
.no-events-container h2 {
  font-size: 24px;
}
.no-events-container p {
  color: #8f8f8f;
  width: 272px;
  margin: 0 auto;
}
.fixed-container.no-header .main-container {
  top: 0;
}
.fixed-container.has-upcoming-events .main-container,
.fixed-container.has-upcoming-events .side-menu,
.fixed-container.has-upcoming-events .side-menu-bg {
  top: 145px;
}
.fixed-container.has-upcoming-events .side-menu {
  height: calc(100% - 147px);
}
.main-container {
  position: relative;
  top: 72px;
  overflow: hidden;
}
.fixed-container.has-upcoming-events .main-container {
  min-height: calc(100% - 182px);
}
.fixed-container .side-menu {
  position: absolute;
  right: -310px;
  border-left: 1px solid black;
  padding-left: 15px;
  padding-top: 20px;
  width: 310px;
  height: calc(100% - 72px);
  top: 72px;
  background: #ede5d4;
  z-index: 2;
  display: none;
}

.fixed-container.menu-open {
  overflow: hidden;
  height: 100vh !important;
}

.fixed-container.menu-open .side-menu {
  right: 0;
  z-index: 2;
  display: block;
}

.fixed-container .side-menu-bg {
  position: absolute;
  top: 72px;
  background: black;
  width: 100%;
  height: calc(100% - 72px);
  z-index: 1;
  opacity: 20%;
  display: none;
}

.fixed-container.menu-open .side-menu-bg {
  display: block;
}

.fixed-container .side-menu h3 {
  font-size: 24px;
  margin-top: 15px;
}
.fixed-container .side-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.fixed-container .side-menu ul li {
  margin-bottom: 5px;
}
.menu-open-button-container {
  background: #efe4cd;
  border: none;
  margin-left: 10px;
  border-radius: 6px;
  padding: 7px 10px;
  color: black !important;
}
.menu-close-button-container {
  background: #efe4cd;
  border: none;
  margin-left: 10px;
  border-radius: 50%;
  padding: 7px 10px;
  color: black !important;
}

.side-menu .profile-container {
  width: 100%;
  box-shadow: 0px 2px 0px black;
  border-radius: 10px;
  border: 1px black solid;
  margin-bottom: 20px;
  padding: 12px;
}
.profile-container img {
  width: 54px;
  height: 54px;
  box-shadow: 0px 1.687500238418579px 0px black;
  border-radius: 27px;
  border: 0.84px black solid;
  margin-right: 10px;
}
.profile-details {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}
.profile-container .profile-details .profile-name {
  display: flex;
}
.profile-container .profile-details .manage-account-link {
  margin-top: -5px;
  display: flex;
  color: #808080;
  font-size: 12px;
  text-decoration: underline;
}
.side-menu .logout-link {
  position: absolute;
  bottom: 45px;
}
.side-menu .logout-link svg {
  margin-right: 5px;
}
.event-details-container .event-details-header {
  font-size: 18px;
  font-weight: 600;
}
.event-details-container .event-description .event-description-read-more {
  display: none;
  color: #82634a;
  text-decoration: underline;
}
.event-details-container .event-description.clipped span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.event-details-container
  .event-description.clipped
  .event-description-read-more {
  display: block;
}
.event-details-container .about-event-container {
  background: #fdfbf9;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 2px 2px 0px black;
  border: 1px black solid;
}
.event-details-container .about-event-container h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.rsvp-details {
  background: #fdfbf9;
  padding: 16px 1rem 16px 1rem;
  margin-left: -15px;
  margin-bottom: -20px;
  border-radius: 10px 10px 0px 0px;
  border: solid 1px black;
  border-bottom: 0px;
  margin-top: 40px;
  position: absolute;
  width: 100%;
  left: 15px;
  bottom: 22px;
}
.rsvp-details .price-details {
  flex-direction: column;
}
.rsvp-details .price-details span {
  display: block;
}
.rsvp-details .price-details h3 {
  font-size: 20px;
  display: block;
}
.rsvp-details .btn {
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
}
.rsvp-details .btn.disabled {
  box-shadow: none;
  background: #e6e6e6;
  color: #161616;
  opacity: 1;
}

.rsvp-details.non-hosted {
  padding: 16px 1rem 16px 1rem;
  display: block !important;
}

.rsvp-details.non-hosted .btn {
  width: 100%;
  display: block !important;
}

.event-checkout-container .rsvp-select-tickets {
  width: 100%;
  padding: 10px 14px;
  background: white;
  box-shadow: 2px 2px 0px black;
  border-radius: 10px;
  border: 1px black solid;
}
.event-checkout-container .rsvp-select-tickets .rsvp-label {
  align-items: center;
}
.event-checkout-container .rsvp-select-tickets .ticket-counter-container {
  align-items: center;
}
.event-checkout-container
  .rsvp-select-tickets
  .ticket-counter-container
  .ticket-counter-change {
  box-shadow: none;
  border: solid 1px #828282;
  background: white;
  height: 26px;
  padding: 9px !important;
  border-radius: 8px;
  align-items: center;
}
.event-checkout-container
  .rsvp-select-tickets
  .ticket-counter-container
  .ticket-counter-change.disabled {
  background: #dbdbdb;
  color: #8f8f8f;
}
.event-checkout-container
  .rsvp-select-tickets
  .ticket-counter-container
  .ticket-count {
  font-size: 16px;
  background: black;
  color: white;
  padding: 5px 12px;
  border-radius: 4px;
  margin: 0px 5px;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.ticket-count span:first-of-type {
  display: flex;
  font-size: 16px;
  line-height: 16px;
}
.ticket-count span:last-of-type {
  font-size: 12px;
  display: flex;
  line-height: 12px;
}

.event-checkout-container
  .rsvp-user-information
  .form-control-row:first-of-type {
  margin-top: 24px;
}
.event-checkout-container
  .rsvp-user-information
  .form-control-row:last-of-type {
  margin-bottom: 24px;
}
.event-checkout-container .rsvp-user-information .form-control-row {
  margin-top: 16px;
  width: 100%;
}
.event-checkout-container .rsvp-user-information .form-control-row .form-label {
  margin-bottom: 0;
}
.event-checkout-container
  .rsvp-user-information
  .form-control-row
  .form-control {
  background: #f7f1e5;
  border: solid 2px black;
  border-radius: 8px;
}

.event-checkout-container .rsvp-terms {
  background: #fdfbf9;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 2px 2px 0px black;
  border: 1px black solid;
}
.event-checkout-container .rsvp-terms h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.event-checkout-container .rsvp-total-information {
  background: white;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 2px 2px 0px black;
  border: 1px black solid;
}
.event-checkout-container .rsvp-total-information .sub-total-price {
  font-size: 16px;
  font-weight: 100;
  color: #565966;
  margin-bottom: 8px;
}
.event-checkout-container .rsvp-total-information .total-price {
  font-size: 20px;
  font-weight: 600;
  color: #232323;
  margin-bottom: 8px;
}
.alert-full-screen {
  position: absolute;
  z-index: 1000;
  top: 0;
  text-align: center;
  height: 100vh;
  background: #ede5d4;
  width: 100%;
  max-width: 450px;
  display: none;
}
.fixed-container.success-alert-open,
.fixed-container.error-alert-open {
  overflow: hidden;
  height: 100vh !important;
}
.fixed-container.success-alert-open .alert-full-screen.success-alert {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.fixed-container.success-alert-open .alert-full-screen.error-alert {
  display: none;
}
.fixed-container.error-alert-open .alert-full-screen.error-alert {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.fixed-container.error-alert-open .alert-full-screen.success-alert {
  display: none;
}

.event-checkout-container .alert-danger {
  padding: 15px;
  background: #e1c5b1;
  color: #151515 !important;
  font-weight: 600;
}

.upcoming-event-details .event-card-container {
  background: white;
  border-radius: 10px;
  border: solid 1px black;
}

.upcoming-event-details .event-card-container .event-card {
  background: white;
  border: solid 1px white;
  box-shadow: none;
  border-bottom: dashed 1px black;
}
.upcoming-event-details .event-card-container .order-details span {
  color: #757575;
}
.upcoming-event-details .event-card-container .order-details h3 {
  font-size: 18px;
}
.upcoming-event-details
  .event-card-container
  .upcoming-event-seperator
  span:first-of-type {
  position: absolute;
  background: #ede5d4;
  width: 12px;
  height: 21px;
  border-radius: 0 21px 21px 0;
  border: solid 1px black;
  top: -21px;
  left: 5px;
  transform: translate(-50%, -50%);
  border-left: 0px;
}
.upcoming-event-details .event-card-container .upcoming-event-seperator {
  position: relative;
}
.upcoming-event-details
  .event-card-container
  .upcoming-event-seperator
  span:last-of-type {
  position: absolute;
  background: #ede5d4;
  width: 12px;
  height: 21px;
  border-radius: 21px 0 0 21px;
  border: solid 1px black;
  top: -21px;
  right: -7px;
  transform: translate(-50%, -50%);
  border-right: 0px;
}
.upcoming-event-details .add-to-calendar-container {
  /*background: #FDFBF9;*/
  /*padding: 16px 1rem;*/
  /*margin-left: -15px;*/
  /*width: calc(100% + 34px);*/
  /*margin-bottom: -20px;*/
  /*border-radius: 10px 10px 0px 0px;*/
  /*border: solid 1px black;*/
  /*border-bottom: 0px;*/
  margin-top: 40px;
  /*position: relative;*/
  /*left: -2px;*/
}
.upcoming-event-details .add-to-calendar-container .btn {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
}

#loginContainer {
  background: white;
  margin: 8px;
  text-align: center;
  border: solid 2px black;
  border-radius: 8px;
  box-shadow: 1.9351048469543457px 1.9351048469543457px 0px black;
  padding: calc(((100vh - 800px) / 2) - 5px) 0;
}
#loginContainer #logoImage {
  margin-top: 32px;
  margin-bottom: 16px;
}
#loginContainer #logoImagePlaceholder {
  margin: 0 10px;
  width: calc(100% - 20px);
}
#loginContainer h1 {
  font-size: 24px;
  margin: 24px 0;
}
#loginContainer .google-sso-button {
  background: white;
  box-shadow: none;
  width: calc(100% - 120px);
  margin: 0 60px;
  padding: 10px 0px;
  border: solid 2px #dbdbdb;
  border-radius: 40px;
  margin-bottom: 30px;
  font-size: 16px;
}
#loginContainer .google-sso-button img {
  margin-right: 10px;
}
